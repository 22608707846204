import logo from "../assets/img/logo.svg";
import linkedinIcon from "../assets/img/linkedin-icon.svg";
import githubIcon from "../assets/img/github-icon.svg";
import { LINKEDIN_URL } from '../Constants.js';
import { GITHUB_URL } from '../Constants.js';
import { Container, Row, Col } from "react-bootstrap";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
          <div className="social-icon">
                <a href={LINKEDIN_URL} target="_blank"><img src={linkedinIcon}/></a>
                <a href={GITHUB_URL} target="_blank"><img src={githubIcon}/></a>
              </div>
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
