import project1Image from "../assets/img/project-1-image.png";
import project2Image from "../assets/img/project-2-image.png";
import project3Image from "../assets/img/project-3-image.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import TrackVisibility from 'react-on-screen';
import 'animate.css';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";

export const Projects = () => {

  const projects = [
    {
      title: "Decryption Service",
      description: "Java Application",
      imgUrl: project1Image,
      link: "https://github.com/jgarciarivera/decryption-service",
    },
    {
      title: "Prime Cinema",
      description: "Mobile Application",
      imgUrl: project2Image,
      link: "https://github.com/jgarciarivera/prime-cinema",
    },
    {
      title: "Mastermind",
      description: "Game",
      imgUrl: project3Image,
      link: "https://github.com/jgarciarivera/mastermind",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              <div>
              <h2>Projects</h2>
                <p>Below are some of the projects I've worked on in the past few years. Check back at a later time since this list is regularly updated!</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}