import headerImage from "../assets/img/header-img.svg";
import 'animate.css';
import { Container, Row, Col } from "react-bootstrap";

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
              <div>
                <span className="tagline">Welcome To My Portfolio</span>
                <h1>Hi! I'm Jorge Garcia-Rivera</h1>
                  <p>
                  I am an experienced software engineer with over three years of professional development expertise focused on backend development. I am actively interested in cloud technologies as well as full-stack web development. My primary proficiencies are in C# and .NET, although I am currently learning JavaScript and React. I am highly motivated to deliver software solutions that are scalable, performant, and maintainable.
                  </p>
                  <p>
                  My journey began in 2018 when I graduated from the University of Houston with a Bachelor's Degree in Computer Science. During my tenure at Pariveda Solutions, a technology consulting firm, I gained experience in technologies such as C#, .NET, and AWS. Recently, I took a temporary break from my career to address a family emergency. With the situation now resolved, I am eager to resume my professional journey and leverage my skills, dedication, and expertise in a new and challenging role!
                  </p>
              </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
                <div>
                  <img src={headerImage} alt="Header Img"/>
                </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}